import { Link } from "gatsby"
import React, { useState } from 'react';

import Seo from "../components/seo"
import Layout from "../components/layout"
import Article from "../components/article"
import getLanguage from "../utilt/getLangueage"
import useWindowSize from "../utilt/useWindowSize"

//CSS
import {panel} from "./studies.module.css"
import {panelTitle} from "./studies.module.css"
import {desc} from "./studies.module.css"
import {panel2} from "./studies.module.css"
import {panelsHolizon} from "./studies.module.css"
import {panels} from "./studies.module.css"
//CSS2
import {title} from "./regularPage.module.css"
import {titleStudies} from "./regularPage.module.css"

import studies_drawingLesson from "../images/studies/studies_drawingLesson.jpg"
import studies_drawingLesson2 from "../images/studies/studies_drawingLesson2.jpg"
import studies_clayRubber from "../images/studies/studies_clayRubber.jpg"
import studies_displayingPicture from "../images/studies/studies_displayingPicture.jpg"

//import seoImg from "../images/seo/seo_studies.jpg"


/**
 * パネル・コンポーネント
 */
const Panel = props => {
  return (
    <a
      href={props.goto}
      target="_blank"
      rel="noopener noreferrer"
      className={panel}
      style={{
        backgroundImage: `url(${props.bg})`,
        borderColor: props.borderColor
      }}
    >
      <div
        className={panelTitle}
        style={{
          marginTop: `${props.winSize.width > 480 ? 20 : 20 * (props.winSize.width / 480) ** 4}px`,
          fontSize: `${props.winSize.width > 480 ? 1.75 : 1.5 + 0.25 * (props.winSize.width / 480) ** 4}rem`,
        }}
      >
        {props.title}
      </div>
      <div className={desc}>
        {props.desc}
      </div>
    </a >
  )
}

const Panel2 = props => {
  return (
    <div
    >
      <Link
        to={props.goto}
        className={panel2}
        style={{
          backgroundImage: `url(${props.bg})`,
        }}
      >
        <div
          className={panelTitle}
          style={{
            fontWeight: "550",
            fontSize: `${props.winSize.width > 480 ? 2.25 : 1.75 + 0.25 * (props.winSize.width / 480) ** 4}rem`,
          }}
        >
          {props.title}
        </div>
      </Link>
    </div>
  )
}

/**
 * ページ
 */
const Studies = (props) => {
  const [lang, setLang] = useState(getLanguage());
  function setLanguage(enOrJa) {
    setLang(enOrJa);
  }
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }

  return (
    <Layout
      checked="studies"
      winSize={winSize}
    >
      <Seo
        title="Studies"
        description={lang !== "ja" ?
          "This is the page to upload the studies" :
          "習作をアップロードしてみる頁です"
        }
        lang={lang}
        image="https://www.drawinghell.com/ogp_images/seo_studies.jpg"
        pathname="/studies"
      />
      <Article
        isLangBar="true"
        winSize={winSize}
        setLang={setLanguage}
        lang={props.lang}
      >
        <h1 className={`${title} ${titleStudies}`}>Studies</h1>
        <div className={panelsHolizon}>
          <div className={panels}>
            <Panel2
              goto="/study/displayingPicture"
              title={
                lang !== "ja" ?
                  "Displaying Picture" :
                  "徐々に表われる絵"
              }
              bg={studies_displayingPicture}
              winSize={winSize}
            />
            <Panel
              goto="https://www.drawinghell.com/studies/clay-rubber-man"
              title={
                lang !== "ja" ?
                  "Clay|Rubber Man" :
                  "ねんど｜ゴム人間"
              }
              bg={studies_clayRubber}
              desc={
                lang !== "ja" ?
                  "Adjust grabbing range: Right click and slide (Touch device: Multi-touch and slide), Switch between clay and rubber: Double click" :
                  "掴み範囲調節：右クリック＆スライド（タッチデバイスはマルチタッチ＆スライド）、ねんど｜ゴムの切り替え：ダブルクリック"
              }
              winSize={winSize}
            />

            <Panel
              goto="https://www.drawinghell.com/studies/drawing-lesson2"
              title={
                lang !== "ja" ?
                  "Drawing Lesson (Paint tool)" :
                  "ドローイング練習(ペイントツール)"
              }
              bg={studies_drawingLesson2}
              desc={
                lang !== "ja" ?
                  "Save: Right click (Touch device: Multi-touch)" :
                  "保存：右クリック（タッチデバイスはマルチタッチ）"
              }
              winSize={winSize}
            />

            <Panel
              goto="https://www.drawinghell.com/studies/drawing-lesson"
              title={
                lang !== "ja" ?
                  "Drawing Lesson (Mouse trails)" :
                  "ドローイング練習(マウストレイル)"
              }
              bg={studies_drawingLesson}
              desc={
                lang !== "ja" ?
                  "Left click: change color, Middle cllick: change stroke, Right click: change size, Double click: change all three" :
                  "左クリック：色変更、中クリック：軌跡変更、右クリック：サイズ変更、ダブルクリック：上記三つ全て変更"
              }
              winSize={winSize}
            />
          </div>

        </div>
      </Article>
    </Layout>
  )
}

export default Studies
